<template>
  <section class="search-no-objects">
    <img src="~/assets/img/no-data.svg" alt="no-data" >
    <h3 class="font--h4-2 mobile-font--h4 text-black-monochrome">No result found</h3>
    <p class="font--b2 mobile-font--b2 text-black-monochrome">
      We couldn’t find any property with those filters that you searched.
    </p>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.search-no-objects {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: var(--shadow-light);
}

@media (max-width: 65rem) {
  @include mobile-fonts;
}
</style>
