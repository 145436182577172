import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-auth-middleware": () => import("/vercel/path0/middleware/admin-auth-middleware.ts"),
  "auth-middleware": () => import("/vercel/path0/middleware/auth-middleware.ts"),
  "unit-page-middleware": () => import("/vercel/path0/middleware/unit-page-middleware.ts")
}