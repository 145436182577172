export default defineNuxtPlugin({
  name: "gmMaps",
  enforce: "pre",
  async setup() {
    const config = useRuntimeConfig();
    const loadGmap = () => {
      const gm = document.createElement("script");
      gm.src = `https://maps.googleapis.com/maps/api/js?key=${config.public.gmAPI}&v=weekly`;
      gm.type = "text/javascript";
      gm.async = true;
      document.head.appendChild(gm);
    };
    loadGmap();
  },

  env: {
    islands: true,
  },
});
