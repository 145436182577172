<template>
  <main>
    <div class="backdrop"/>
    <section class="container">
      <base-icon name="Logo_light" size="300" color="var(--white-monochrome)" />
      <div class="container-title">
        <img src="~/assets/img/coming_soon.svg" alt="coming soon" >
        <h4 class="font__huge-subtitle">
          First real world assets property aggregator
        </h4>
      </div>

      <div class="container-subscription">
        <p class="dm-sans font__base-medium">
          Leave your email. We will keep you updated.
        </p>
        <base-subscribe />
      </div>
    </section>
  </main>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
main {
  position: relative;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  .backdrop {
    position: absolute;
    top: 0;
    width: 100dvw;
    height: 100dvh;
    background-color: var(--violet-main);
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -5%;
    bottom: 0;
    left: 0;
    background-image: url(~/assets/img/house_placeholder.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-attachment: fixed;
    opacity: 0.66;
    filter: blur(2px);
    z-index: 2;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 10%,
      rgba(15, 15, 15, 0.1) 100%
    );
    z-index: 3;
  }
  .container {
    position: relative;
    width: fit-content;
    height: 100%;
    margin: auto;
    display: grid;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    padding: 4rem 1rem 4rem 1rem;
    z-index: 4;
    &-title {
      display: grid;
      align-items: center;
      justify-items: center;
      gap: 41px;
      text-align: center;

      :last-child {
        color: var(--white-monochrome);
      }
    }
    &-subscription {
      display: grid;
      justify-items: center;
      gap: 16px;
      p {
        color: var(--white-monochrome);
      }

      // .input {
      //   position: relative;
      //   width: 420px;
      //   height: 61px;
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-between;
      //   align-items: center;
      //   padding: 10px 10px 10px 16px;
      //   border-radius: 40px;
      //   box-shadow: var(--shadow-base);
      //   background: linear-gradient(
      //     to right,
      //     var(--violet-light) 50%,
      //     var(--white-contrast) 50%
      //   );
      //   background-size: 200% 100%;
      //   background-position: right bottom;
      //   transition: background-position 2s ease;
      //   input {
      //     width: 100%;
      //     max-width: 220px;
      //     outline: none;
      //     border: none;
      //     background: var(--white-contrast);
      //     color: var(--black-monochrome-60);
      //   }
      //   .is-done {
      //     position: absolute;
      //     padding: 0 10px 0 16px;
      //     left: 0;
      //     width: 100%;
      //     text-align: center;
      //   }
      // }
      // .animated {
      //   background-position: left bottom;
      // }
    }
  }
}
@media screen and (min-width: 360px) and (max-width: 500px) {
  main {
    .container {
      padding: 4rem 1rem 4rem 1rem;
      &-title {
        gap: 5px;
        :first-child {
          font-size: 50px;
        }
        :last-child {
          font-size: 24px;
        }
      }
      &-subscription {
        display: grid;
        justify-items: center;
        gap: 16px;
        p {
          font-size: 16px;
        }

        .input {
          width: 342px;
          input {
            max-width: 140px;
          }
        }
      }
    }
  }
}
</style>
