export default defineNuxtPlugin((nuxtApp) => {
    if (import.meta.client) {
        nuxtApp.hook('page:finish', (page) => {
            if (page.fullPath !== '/admin') {
                const script = document.createElement('script');
                script.async = true;
                script.src = 'https://www.googletagmanager.com/gtag/js?id=G-P9R70JS4B2';
                document.head.appendChild(script);

                window.dataLayer = window.dataLayer || [];
                function gtag() { dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', 'G-P9R70JS4B2');
            }
        });
    }
});
