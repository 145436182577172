import VueHotjar from "vue-hotjar-next";

export default defineNuxtPlugin({
  name: "hotjar",
  enforce: "post",
  async setup(nuxtApp) {
    nuxtApp.vueApp.use(VueHotjar, {
      id: 5060044,
      isProduction: process.env.NODE_ENV === "production",
      snippetVersion: 6,
    });
  },
  env: {
    islands: true,
  },
});
