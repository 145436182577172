import revive_payload_client_75tzuQIsIe from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mIVWhfMjql from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_K3sJTb0u2x from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7G0gGGTDiA from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.17_magicast@0.3.5_rollup@4.21.1_vite@5.4.4_@types+node@22.5.1_sass@1.78._ttr3nvadchpu2v4ztj6vemdhji/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import gmMaps_KV68qKtSx0 from "/vercel/path0/plugins/gmMaps.ts";
import payload_client_mJ1wgrhYWW from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LoZXPWMK22 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_K6xm7JdG0a from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_C991CLsYEQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_4xOxvo5YnZ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.1_typescript@5.6.2_vue@3.5.4_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_cpk2b7s4as from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_1WaTJJvRMi from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.1_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Nj3VhMMUOS from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.1_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import siteConfig_ODNAPknPcr from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.1_vite@5.4.4_@types+node@22.5.1_sass@1._jjmvld3i2ltxvzkcj5krwbtgtq/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_S9iVsIq4Oa from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.1_vite@5.4.4_@types+node@22.5.1_sass@1._jjmvld3i2ltxvzkcj5krwbtgtq/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_JLwqS8Ztso from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@types+node@22.5.1_h3@1.12.0_ioredis@5.4.1_jiti@1._vnvrxv2bx5uhxruuu6dqhzruge/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_4rIUJNNB1h from "/vercel/path0/node_modules/.pnpm/nuxt-quasar-ui@2.1.5_@quasar+extras@1.16.12_magicast@0.3.5_quasar@2.16.11_rollup@4.21.1/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import gtm_MVzSxF8h1r from "/vercel/path0/plugins/gtm.js";
import rest_api_zNWx1STPxo from "/vercel/path0/plugins/rest-api.js";
import error_handler_kEP5FliEXj from "/vercel/path0/plugins/error-handler.ts";
import hotjar_client_HJG8no80GI from "/vercel/path0/plugins/hotjar.client.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import init_BSSh0QojDj from "/vercel/path0/node_modules/.pnpm/nuxt-schema-org@3.4.0_magicast@0.3.5_rollup@4.21.1_vite@5.4.4_@types+node@22.5.1_sass@1.78.0__jjb64dua736ztc2g72r4hyjtpq/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/i18n/init.js";
export default [
  revive_payload_client_75tzuQIsIe,
  unhead_mIVWhfMjql,
  router_K3sJTb0u2x,
  _0_siteConfig_7G0gGGTDiA,
  gmMaps_KV68qKtSx0,
  payload_client_mJ1wgrhYWW,
  navigation_repaint_client_LoZXPWMK22,
  check_outdated_build_client_K6xm7JdG0a,
  chunk_reload_client_C991CLsYEQ,
  plugin_vue3_4xOxvo5YnZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_cpk2b7s4as,
  switch_locale_path_ssr_1WaTJJvRMi,
  i18n_Nj3VhMMUOS,
  siteConfig_ODNAPknPcr,
  inferSeoMetaPlugin_S9iVsIq4Oa,
  plugin_JLwqS8Ztso,
  plugin_4rIUJNNB1h,
  gtm_MVzSxF8h1r,
  rest_api_zNWx1STPxo,
  error_handler_kEP5FliEXj,
  hotjar_client_HJG8no80GI,
  sentry_3AyO8nEfhE,
  init_BSSh0QojDj
]