import { default as _91id_931mf9pMu3VrMeta } from "/vercel/path0/pages/admin/[slug]/[id].vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as indexZidYEKGUi3Meta } from "/vercel/path0/pages/comparison/index.vue?macro=true";
import { default as indexs8g6T0dsz8Meta } from "/vercel/path0/pages/faq/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as indexYDlMnzOPg9Meta } from "/vercel/path0/pages/search/[id]/index.vue?macro=true";
import { default as indexDqV3Lxa5AvMeta } from "/vercel/path0/pages/search/index.vue?macro=true";
import { default as googleUNaLIGb4GoMeta } from "/vercel/path0/pages/third-party-auth/google.vue?macro=true";
import { default as indexsgW9aTdsiwMeta } from "/vercel/path0/pages/ui-kit/index.vue?macro=true";
import { default as component_45stubmXLrDnw7SeMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmXLrDnw7Se } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_pq3gj62mq2kevd66nmlhd5ro6e/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-slug-id___en",
    path: "/admin/:slug()/:id()",
    meta: _91id_931mf9pMu3VrMeta || {},
    component: () => import("/vercel/path0/pages/admin/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-slug-id___de",
    path: "/de/admin/:slug()/:id()",
    meta: _91id_931mf9pMu3VrMeta || {},
    component: () => import("/vercel/path0/pages/admin/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexG7R2Mya2P4Meta || {},
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "comparison___en",
    path: "/comparison",
    meta: indexZidYEKGUi3Meta || {},
    component: () => import("/vercel/path0/pages/comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "comparison___de",
    path: "/de/comparison",
    meta: indexZidYEKGUi3Meta || {},
    component: () => import("/vercel/path0/pages/comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/faq",
    meta: indexs8g6T0dsz8Meta || {},
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/de/faq",
    meta: indexs8g6T0dsz8Meta || {},
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: indexSsrwCXeuTQMeta || {},
    component: () => import("/vercel/path0/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___de",
    path: "/de/profile",
    meta: indexSsrwCXeuTQMeta || {},
    component: () => import("/vercel/path0/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "search-id___en",
    path: "/search/:id()",
    meta: indexYDlMnzOPg9Meta || {},
    component: () => import("/vercel/path0/pages/search/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-id___de",
    path: "/de/search/:id()",
    meta: indexYDlMnzOPg9Meta || {},
    component: () => import("/vercel/path0/pages/search/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    meta: indexDqV3Lxa5AvMeta || {},
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/search",
    meta: indexDqV3Lxa5AvMeta || {},
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "third-party-auth-google___en",
    path: "/third-party-auth/google",
    component: () => import("/vercel/path0/pages/third-party-auth/google.vue").then(m => m.default || m)
  },
  {
    name: "third-party-auth-google___de",
    path: "/de/third-party-auth/google",
    component: () => import("/vercel/path0/pages/third-party-auth/google.vue").then(m => m.default || m)
  },
  {
    name: "ui-kit___en",
    path: "/ui-kit",
    meta: indexsgW9aTdsiwMeta || {},
    component: () => import("/vercel/path0/pages/ui-kit/index.vue").then(m => m.default || m)
  },
  {
    name: "ui-kit___de",
    path: "/de/ui-kit",
    meta: indexsgW9aTdsiwMeta || {},
    component: () => import("/vercel/path0/pages/ui-kit/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubmXLrDnw7SeMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubmXLrDnw7Se
  },
  {
    name: component_45stubmXLrDnw7SeMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubmXLrDnw7Se
  },
  {
    name: component_45stubmXLrDnw7SeMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubmXLrDnw7Se
  },
  {
    name: component_45stubmXLrDnw7SeMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubmXLrDnw7Se
  }
]